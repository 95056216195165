import React, { useCallback, useEffect, useState } from "react";

import { Alert, Box, Container, Typography, alertClasses } from "@mui/material";

import { usePageLocale } from "../../../contexts/PageLocaleContext";

const ALERT_SESSION_STORAGE_KEY = (locale) =>
  `fmt-activity-alert-state-cache-${locale}`;

const ActivityAlert = ({ children }) => {
  const [alertOpen, setAlertOpen] = useState(true);
  const { locale } = usePageLocale();

  const handleOnClose = useCallback(() => {
    setAlertOpen(false);
    sessionStorage.setItem(ALERT_SESSION_STORAGE_KEY(locale), "false");
  }, [locale]);

  useEffect(() => {
    setAlertOpen(
      (sessionStorage.getItem(ALERT_SESSION_STORAGE_KEY(locale)) ?? "true") ===
        "true"
    );
  }, [locale]);

  return (
    <Box sx={{ position: "relative" }}>
      {alertOpen && (
        <Alert
          icon={false}
          onClose={handleOnClose}
          sx={{
            position: "absolute",
            width: "100%",
            borderRadius: 0,
            boxShadow: "0px 1px 1px rgba(149, 157, 165, 0.2)",
            bgcolor: "#192028",
            paddingY: 0,
            [`& .${alertClasses.action}`]: {
              alignItems: "center",
              paddingY: 0,
            },
            [`& .${alertClasses.message}`]: {
              width: "100%",
            },
          }}
        >
          <Container disableGutters maxWidth="lg">
            <Typography
              component="div"
              color="white"
              variant="caption"
              align="center"
            >
              {children}
            </Typography>
          </Container>
        </Alert>
      )}
    </Box>
  );
};

export default ActivityAlert;
