import React from "react";

import { Box, Container } from "@mui/material";

const Footer = () => (
  <footer>
    <Container>
      <Box
        display="flex"
        alignItems="center"
        rowGap={2}
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="center"
        pb={4}
        pt={2}
      />
    </Container>
  </footer>
);

export default Footer;
