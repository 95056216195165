import React from "react";

import { IconButton } from "@mui/material";

import LogoutIcon from "@mui/icons-material/Logout";

import { APP_ROUTES, useAuth } from "market-webapp-commons";

const LogoutButton = () => {
  const { logout } = useAuth();
  return (
    <IconButton
      color="text"
      onClick={() => {
        if (logout) logout({ redirect: APP_ROUTES.HOME });
      }}
    >
      <LogoutIcon fontSize="small" />
    </IconButton>
  );
};

export default LogoutButton;
