import React, { useMemo } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { Box, Button, Icon, Typography } from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";

import { FormattedMessage } from "react-intl";

import { usePageLocale } from "../../../contexts/PageLocaleContext";

const MenuLangSwitch = ({ lang, label, onClose }) => {
  const router = useRouter();
  const { locale } = usePageLocale();
  const isActive = useMemo(() => locale === lang, [locale, lang]);

  return (
    <Link href={router.asPath} locale={lang} replace passHref>
      <Button
        color="text"
        fullWidth
        size="small"
        startIcon={isActive ? <LanguageIcon /> : <Icon fontSize="small" />}
        onClick={onClose}
        sx={{
          justifyContent: "flex-start",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: isActive ? 700 : 400 }}
        >
          <FormattedMessage id={label} />
        </Typography>
      </Button>
    </Link>
  );
};

const MenuLangSelector = ({ supportedLanguages, onClose }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      px: 1,
      width: "100%",
    }}
  >
    <Button
      size="small"
      underline="none"
      fullWidth
      startIcon={<Icon />}
      sx={{
        justifyContent: "flex-start",
      }}
      disabled
    >
      <Typography variant="subtitle">
        <FormattedMessage id="lang" />
      </Typography>
    </Button>
    {supportedLanguages.map((lang) => (
      <MenuLangSwitch
        key={lang}
        label={`switch-lang-${lang}`}
        lang={lang}
        onClose={onClose}
      />
    ))}
  </Box>
);

export default MenuLangSelector;
