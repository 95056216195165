import React from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { Button, Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";

const MenuLink = ({ href, startIcon, label, onClick, ...props }) => {
  const router = useRouter();
  const isActive = router.asPath === href;

  return (
    <Link key={href} href={href} passHref>
      <Button
        color={isActive ? "primary" : "text"}
        fullWidth
        size="small"
        startIcon={startIcon}
        sx={{
          justifyContent: "flex-start",
        }}
        onClick={onClick}
        {...props}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: isActive ? 700 : 400,
          }}
        >
          <FormattedMessage id={label} />
        </Typography>
      </Button>
    </Link>
  );
};

export default MenuLink;
