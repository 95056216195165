import React from "react";

import Link from "next/link";
import { withRouter } from "next/router";

import { Box, Button } from "@mui/material";

import { APP_ROUTES } from "market-webapp-commons";
import { FormattedMessage } from "react-intl";

import { LocalizationProvider } from "../../../contexts/PageLocaleContext";
import TranslationTexts from "./LoginButton.translation.json";

const LoginButton = ({ router }) => (
  <LocalizationProvider translationTexts={TranslationTexts}>
    <Link href={APP_ROUTES.LOGIN(router.asPath)} passHref>
      <Button color="text">
        <Box component="span" sx={{ display: { xs: "none", sm: "inline" } }}>
          <FormattedMessage id="login-text" />
        </Box>
        <Box component="span" sx={{ display: { xs: "inline", sm: "none" } }}>
          <FormattedMessage id="login-text-mobile" />
        </Box>
      </Button>
    </Link>
  </LocalizationProvider>
);

export default withRouter(LoginButton);
