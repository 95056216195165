import React from "react";

import { Box, Divider, Drawer, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import ShopTwoIcon from "@mui/icons-material/ShopTwo";

import { useAuth } from "market-webapp-commons";

import { LocalizationProvider } from "../../contexts/PageLocaleContext";
import MenuLangSelector from "./MenuLangSelector";
import MenuLink from "./MenuLink";
import TranslationTexts from "./index.translation.json";

const MainSidebar = ({ onClose, open }) => {
  const { isAuthenticated, logout } = useAuth();
  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 300 } }}
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 100,
      }}
    >
      <LocalizationProvider translationTexts={TranslationTexts}>
        <Box sx={{ pt: 1, px: 1, textAlign: "end", width: "100%" }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            px: 1,
          }}
        >
          {isAuthenticated && (
            <>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  px: 1,
                }}
              >
                <MenuLink label="btn-home" startIcon={<HomeIcon />} href="/" />
                <MenuLink
                  label="btn-my-tickets"
                  startIcon={<ShopTwoIcon />}
                  href="/my-tickets"
                />
                <MenuLink
                  label="btn-logout"
                  startIcon={<LogoutIcon />}
                  color="text"
                  href="/"
                  onClick={() => {
                    if (logout) logout();
                  }}
                />
              </Box>
            </>
          )}

          <Divider sx={{ my: 2 }} />
          <MenuLangSelector
            supportedLanguages={["zh", "en"]}
            onClose={onClose}
          />
          <Divider sx={{ my: 2 }} />
        </Box>
      </LocalizationProvider>
    </Drawer>
  );
};

export default MainSidebar;
