import React from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Link as MuiLink,
  Toolbar,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import { useAuth } from "market-webapp-commons";
import { FormattedMessage } from "react-intl";

import { LocalizationProvider } from "../../contexts/PageLocaleContext";
import Logo from "../Logo";
import ActivityAlert from "./components/ActivityAlert";
import LoginButton from "./components/LoginButton";
import LogoutButton from "./components/LogoutButton";
import TranslationTexts from "./index.translation.json";

const MainNavbar = ({ onOpenSidebar }) => {
  const router = useRouter();
  const { isAuthenticated, user } = useAuth();

  return (
    <LocalizationProvider translationTexts={TranslationTexts}>
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "background.paper",
          color: "text.secondary",
          position: "sticky",
        }}
      >
        <Toolbar variant="dense">
          <Logo sx={{ mr: 1 }} />
          <Link href="/" passHref>
            <MuiLink
              color="textPrimary"
              noWrap
              underline="none"
              variant="subtitle1"
            >
              <Hidden implementation="css" smUp>
                <FormattedMessage id="event-name-mobile" />
              </Hidden>
              <Hidden implementation="css" smDown>
                <FormattedMessage id="event-name" />
              </Hidden>
            </MuiLink>
          </Link>

          <Box sx={{ flexGrow: 1 }} />

          {!isAuthenticated && <LoginButton />}
          {isAuthenticated && (
            <Box sx={{ display: "flex", columnGap: 0.5 }}>
              <Link href="/my-tickets" passHref>
                <Button
                  color={router.asPath === "/my-tickets" ? "primary" : "text"}
                  sx={{
                    fontWeight: router.asPath === "/my-tickets" ? 700 : 400,
                  }}
                >
                  <FormattedMessage id="user-tickets" />
                </Button>
              </Link>
              <Hidden mdDown implementation="css">
                <Button color="text" onClick={onOpenSidebar}>
                  <FormattedMessage id="user-greetings" />, {user.name}
                </Button>
                <LogoutButton />
              </Hidden>
            </Box>
          )}
          <IconButton color="textPrimary" onClick={onOpenSidebar}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <ActivityAlert>
          <FormattedMessage id="header-caption" />
        </ActivityAlert>
      </AppBar>
    </LocalizationProvider>
  );
};

export default MainNavbar;
