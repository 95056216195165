import { React, useEffect, useState } from "react";

import { useRouter } from "next/router";

import { Box } from "@mui/material";

import Footer from "../components/Footer";
import MainNavbar from "../components/MainNavbar";
import MainSidebar from "../components/MainSidebar";

const MainLayout = ({ children, defaultState = { isSidebarOpen: false } }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(defaultState.isSidebarOpen);
  const router = useRouter();
  useEffect(() => setSidebarOpen(false), [router?.asPath]);

  useEffect(() => {
    const fn = async () => {
      if (!window.particlesJS) {
        await import("particles.js");
      }
      window.particlesJS.load("particles-js", "/particles/config.json.conf");
    };
    fn();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        id="particles-js"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      />
      <MainNavbar onOpenSidebar={() => setSidebarOpen(true)} />
      <MainSidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />
      <Box sx={{ flexGrow: 1, position: "relative" }}>{children}</Box>
      <Footer sx={{ flexShrink: 0 }} />
    </Box>
  );
};

export default MainLayout;
